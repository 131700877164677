(function ($) {
  $(window).load(function(){
    setTimeout(() => {
      if ($(window).width() < 768) {
        return;
      }

      if (-1 === document.location.search.indexOf('form[sent]') || -1 === document.location.hash.indexOf('#form')) {
        return;
      }

      var $stickyHeader = $('.header.sticky');
      var $message = $('.form_success_message');

      if (!$stickyHeader.length || !$message.length) {
        return;
      }

      var currentScrollTop = $('html').scrollTop();
      var newScrollTop = $message.offset().top - $stickyHeader.outerHeight() - 25;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({ scrollTop: $message.offset().top - $stickyHeader.outerHeight() - 25 });
    }, 500);

    $('.owl-carousel:not(.slider)').owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // navigation
      nav: true,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],

      dots: true,

      // drag options
      mouseDrag: false
    })

    $(".slider").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 15,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: false,
      dots: true,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2,
          margin: 30
        },
        992: {
          items: 3,
          margin: 30
        },
        1200: {
          items: 3,
          margin: 30
        }
      }
    });
  });

  // sticky header
  $(document).ready(function(){
    var windowWidth = $(window).width();
    if (windowWidth > 0) {
      $(window).scroll(function(){
        if ($(window).scrollTop() > 0) {
          $('body').addClass('sticky');
          $('.header').addClass('sticky');
        }
        else {
          $('body').removeClass('sticky');
          $('.header').removeClass('sticky');
        }
      });
    }

    // fancybox
    $('a[data-fancybox]').fancybox({
      buttons: [
          //"zoom",
          //"share",
          //"slideShow",
          //"fullScreen",
          //"download",
          "thumbs",
          "close"
      ]
    });

    // scroll-to-content
    $('.js-3w-scroll-to-content').on('click', function(event) {
      event.preventDefault();
      var identifier = $(this).attr('data-target');
      var $element = $(identifier);

      if ($element) {
        $('html, body').animate({ scrollTop: $element.offset().top }, 500);
      }
    });

  });
})(jQuery);
